import React, { useState } from 'react';
import moment from 'moment';
import { NumericFormat } from "react-number-format";
import ExtendsDateModal from '../modal/extendsDateModal';
import Modal from 'react-modal';

const LicencesHistory = ({ licenseInfo }) => {

    const [extendsDateModalIsOpen, setExtendsDateModalIsOpen] = useState(false);

    const dateFormat = (dateString) => {
        return moment(dateString).format('Do MMM YYYY')
    }

    const purchaseDate = (dateString) => {
        return moment(dateString).format('Do MMM YYYY')
    }

    const openExtendsDateModal = () => {
        setExtendsDateModalIsOpen(true);
    };

    const closeExtendsDateModal = () => {
        setExtendsDateModalIsOpen(false);
    };

    return (
        <>
            <div className='d-flex justify-content-start align-items-center'>
                <h3 className='fw-bold font-26 mb-4 licences-history-title'>Licences History</h3>
               {/* <button type='button' onClick={openExtendsDateModal} className='btn btn-md btn-secondary px-4'>Extend Course Date</button> */}
            </div>

            <table className="table table-striped table-responsive">
                <thead className="thead-dark">
                    <tr style={{ backgroundColor: 'var(--bs-table-bg)' }}>
                        <th className="text-center w-10">S. No.</th>
                        <th className=" col">Purchase Date</th>
                        <th className=" col">Course Name</th>
                        <th align='right' className=" col text-right">Amount(₹)</th>
                        <th className=" col text-center">Licences Assigned</th>
                        <th className=" col">Expiry Date</th>
                        <th className=" w-25">Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    {licenseInfo && licenseInfo?.sort((a, b) => new Date(b?.purchase_date) - new Date(a?.purchase_date)).map((data, i) => {
                        return <tr key={i}>
                            <td align="center">{i + 1}</td>
                            <td>{purchaseDate(data?.purchase_date)}</td>
                            <td className='text-capitalize'>{data?.course_title}</td>
                            <td align='right'> <div className='w-10'>
                                <NumericFormat value={data?.total_order_cost} thousandsGroupStyle="lakh" thousandSeparator="," className='numeric text-end' />
                            </div>
                            </td>
                            <td align="center">{data?.total_assigned}</td>
                            <td>{dateFormat(data?.expiry_date)}</td>
                            <td>{data?.remark}</td>
                        </tr>
                    })}
                </tbody>
            </table>

            {/* <Modal
                isOpen={extendsDateModalIsOpen}
                contentLabel="Active Modal"
            >
                <ExtendsDateModal
                    onRequestClose={closeExtendsDateModal}
                />
            </Modal> */}


        </>

    )
}

export default LicencesHistory;