import React, { useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// import './dashboard.css';

Chart.register(...registerables);

const BarChart = ({course_status}) => {
  const barWidth = 40;

  const labels = ['Licenses Bought', 'Invitation Sent', 'Invite Accepted', 'In Progress', 'Course Completed', 'Deactivated', 'Expired Licenses'];
  const completedData = [
    parseInt(course_status.total_assigned),
    parseInt(course_status.total_used),
    parseInt(course_status.accepted),
    parseInt(course_status.inprogress),
    parseInt(course_status.completed),
    parseInt(course_status.deactivate),
    parseInt(course_status.total_expired)
  ];

  const remainingAssigned = 0;
  const remainingInviteSent = parseInt(course_status.total_assigned) - parseInt(course_status.total_used);
  const remainingInviteAccepted = parseInt(course_status.total_used) - parseInt(course_status.accepted);
  const remainingInProgress = parseInt(course_status.notstarted);//parseInt(course_status.accepted) - parseInt(course_status.inprogress);
  const remainingCompleted = parseInt(course_status.completed) == 0 ? parseInt(course_status.inprogress) : 0 ;

  const remainingData = [
    remainingAssigned,
    remainingInviteSent,
    remainingInviteAccepted,
    remainingInProgress,
    remainingCompleted
  ];

  const colors = ['#146B90', '#F2D7D7', 'rgba(244, 181, 50, 0.4)', 'rgba(245, 147, 144, 0.4)', 'rgba(34, 162, 152, 0.4)'];

  const datasets = [{
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          const dataPoint = tooltipItem.raw;
          const label = tooltipItem.label;
          return ` ${label.charAt(0).toUpperCase() + label.slice(1)}` + ' : ' + `${dataPoint} ` + 'Users';
        },
      }
    },
    backgroundColor: ['#146B90', '#ED483D', '#F4B532', '#F59390', '#22A298', '#6A4C93', 'rgb(37, 150, 190)'],
    borderColor: ['#146B90', '#ED483D', '#F4B532', '#F59390', '#22A298', '#6A4C93', 'rgb(37, 150, 190)'],
    data: completedData,
    barThickness: barWidth,
    stack: 'stack2',
    borderWidth: 0.5,
    categoryPercentage: 0.85, // Adjust this value for spacing
    barPercentage: 0.95, // Adjust this value for spacing
    },
    {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const dataPoint = tooltipItem.raw;
            const label = tooltipItem.label;
            if (label === 'In Progress') {
              return ` Not Started` + ' : ' + `${dataPoint} ` + 'Users';
            } else if (label === 'Invitation Sent') {
              return ` Licenses Remaining` + ' : ' + `${dataPoint} ` + 'Users';
            } else if (label === 'Invite Accepted') {
              return ` Not Accepted` + ' : ' + `${dataPoint} ` + 'Users';
            } else if (label === 'Course Completed') {
              return ` Course Incomplete` + ' : ' + `${dataPoint} ` + 'Users';
            }
            return ` ${label.charAt(0).toUpperCase() + label.slice(1)}` + ' : ' + `${dataPoint} ` + 'Users';
          },
        }
      },
      backgroundColor: colors.map(color => createDiagonalPattern(color)),
      borderColor: ['#146B90', '#F2D7D7', 'rgba(244, 181, 50, 0.4)', 'rgba(245, 147, 144, 0.4)', 'rgba(34, 162, 152, 0.4)'],
      data: remainingData,
      barThickness: barWidth,
      stack: 'stack2',
      borderWidth: 0.5,
      categoryPercentage: 0.85, // Adjust this value for spacing
      barPercentage: 0.95, // Adjust this value for spacing
    }
  ];
  // };

  function createDiagonalPattern(color) {
    // create a 10x10 px canvas for the pattern's base shape
    const shape = document.createElement('canvas');
    shape.width = 10;
    shape.height = 10;
    const c = shape.getContext('2d');

    // draw the diagonal lines
    c.strokeStyle = color;
    c.beginPath();
    c.moveTo(2, 0);
    c.lineTo(10, 8);
    c.stroke();
    c.beginPath();
    c.moveTo(0, 8);
    c.lineTo(2, 10);
    c.stroke();

    // create the pattern from the shape
    return c.createPattern(shape, 'repeat');
  }

  const data = {
    labels: labels,
    datasets: datasets,
  };
 // console.log(data);

  const options = {
    indexAxis: 'y',
    responsive: true,
    //   duration: 0,
    //   onComplete: function () {
    //     let myChart = this;
    //     let chartArea = myChart.chartArea;
    //     let { left, top } = chartArea;
    //     const datasets = myChart.data.datasets;
    //     for (let datasetIndex = 0; datasetIndex < datasets.length; datasetIndex++) {
    //       const meta = myChart.getDatasetMeta(datasetIndex);
    //       const data = datasets[datasetIndex].data;
  
    //       for (let index = 0; index < data.length; index++) {
    //         let bar = meta.data[index];
    //         let value = data[index];
    //         if (value != 0) {
    //           let xPos = bar.x - barWidth;
    //           let yPos = bar.y; // Adjust yPos for horizontal bar chart
              
    //           // if (xPos < left) {
    //           //   xPos = left + 5;
    //           // }

    //           var ctx = myChart.ctx;
    //           ctx.save();
    //           ctx.textAlign = 'center';
    //           ctx.textBaseline = 'middle';
    //           ctx.fillStyle = 'black';
    //           ctx.font = 'bold 12px Arial';
    //           ctx.fillText(value, xPos, yPos);
    //           ctx.restore();
    //         } 
    //       }
    //     }
    //   }
    // },
    plugins: {
      tooltip: {
        callbacks: {
          title: function() {
            return '';  
          }
        }
      },
      datalabels: {
        color: 'black',
        anchor: "middle",
        align: "middle",
        font: {
          weight: 'bold'
        },
        formatter: (value) =>  {
          if(value === 0) {
              return ' ';
          }
            return value;
         } 
      },
      legend: {
        display: false,
        position: 'bottom',
        borderRadius: 5
      }
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: true,
        },
      },
      y: {
        display: true,
        grid: {
          display: false,
        },
      }
    },
  };

  return (
    <div className='statistics mt-5 p-5'>
      <h3 className='fw-bold font-22 mb-4'>Statistics</h3>
      <Bar type="bar" data={data} options={options} plugins={[ChartDataLabels]} />
    </div>
  );
};

export default BarChart;
