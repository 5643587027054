import React, { useState, useEffect } from 'react';
import './myProfile.css';
import CustomSelect from '../customSelect/customSelect';
import apiService from '../../../services/apiService';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomToast from '../../Toast';

const EditProfileForm = ({ onClose }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [image, setImage] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedAgeGroup, setSelectedAgeGroup] = useState('');
  const [designation, setDesignation] = useState('');
  const [department, setDepartment] = useState('');
  const [userLocation, setUserLocation] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState('');
  const [removeImage , setImageRemove] = useState(false)

  const location = useLocation();
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      const token = apiService.getAuthToken();
      const result = await apiService.get('get-org-user-info', token);
      if (result && result.status === 'success') {
        setImagePreview(result?.data?.pic && result?.data?.pic != "null"  && result?.data?.pic != null ? result?.data?.pic : '');
        setImage(result?.data?.pic);
        setUserData(result.data);
        setName(result.data.name)
        setEmail(result.data.email)
        setSelectedGender(result?.data?.gender && result?.data?.gender != "null" ? result?.data?.gender : '')
        setPhone(result.data.mobile && result.data.mobile != "null" ? result.data.mobile : '')
        setSelectedAgeGroup(result?.data?.age_group && result?.data?.age_group != "null" ? result?.data?.age_group : '')
        setDesignation(result?.data?.designation && result?.data?.designation != "null" ? result?.data?.designation : '')
        setDepartment(result?.data?.department && result?.data?.department != "null" ? result?.data?.department : '')
        setUserLocation(result?.data?.location && result?.data?.location != "null" ? result?.data?.location : '')
      }
    } catch (error) {
      setError('Error fetching user data');
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const ageGroupOptions = [
    { value: 'Boomers: 1946 - 1964', label: 'Boomers: 1946 - 1964' },
    { value: 'Gen X: 1965 - 1980', label: 'Gen X: 1965 - 1980' },
    { value: 'Millenials: 1981 - 1996', label: 'Millenials: 1981 - 1996' },
    { value: 'Gen Z: 1997 - 2012', label: 'Gen Z: 1997 - 2012' },
    { value: 'Alpha: 2013 - 2025', label: 'Alpha: 2013 - 2025' },
];

const genderOptions = [
    { value: 'Woman', label: 'Woman' },
    { value: 'Man', label: 'Man' },
    { value: 'Others', label: 'Others' },
    { value: 'Prefer not to state', label: 'Prefer not to state'},
  ];

  const handleChange = (e, setState, validator) => {
    const { name, value } = e.target;
    setState(value);
    if (validator) {
      validator(value);
    }
  };

  const handlePhoneChange = (e) => {
    const newPhoneNumber = e.target.value;
    setPhone(newPhoneNumber);
    validatePhone(newPhoneNumber);
};

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        validateEmail(newEmail);
    };

    const handleAgeGroupChange = (value) => {
        setSelectedAgeGroup(value);
    };

    const handleGenderChange = (value) => {
        setSelectedGender(value);
    };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailPattern.test(email);
    setEmailError(isValid ? '' : 'Please enter a valid email address.');
    return isValid;
  };

  const validatePhone = (mobile_no) => {
    const mobilePattern = /^[0-9]{10}$/;
    const isValid = mobilePattern.test(mobile_no);
    if (!isValid) {
      setPhoneError('Please enter a valid 10-digit phone number.');
    } else {
      setPhoneError('');
    }
    return isValid;
  };

  // const validatePhone = (phone) => {
  //   if (isNaN(phone)) {
  //     setPhoneError('Please enter a valid phone number.');
  //     return;
  //   }
  //   const phonePattern = /^[0-9]{10}$/;
  //   const isValid = phonePattern.test(phone);
  //   setPhoneError(isValid ? '' : 'Please enter a valid 10-digit phone number.');
  // };

  // const handleImageChange = (e) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const selectedFile = e.target.files[0];
  //     setSelectedImage(selectedFile);
  //     setImagePreview(URL.createObjectURL(selectedFile));
  //   }
  // };

  const titleCase = (str) => {
    return str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

const handleDepartmentChange = (e) => {
    const newDepartment = e.target.value;
    setDepartment(titleCase(newDepartment));
};

const handleDesignationChange = (e) => {
    const newDesignation = e.target.value;
    setDesignation(titleCase(newDesignation));
};

const handleLocationChange = (e) => {
    const newUserLocation = e.target.value;
    setUserLocation(titleCase(newUserLocation));
};

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const allowedExtensions = ['.png', '.jpg', '.jpeg'];
      const extension = selectedFile.name.split('.').pop().toLowerCase();
      if (allowedExtensions.includes('.' + extension)) {
        setSelectedImage(selectedFile);
        setImagePreview(URL.createObjectURL(selectedFile));
      } else {
        CustomToast.info('Please select a PNG, JPG, or JPEG file.');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if(email && !validateEmail(email)) {
      return
    }
    // if (phone && !validatePhone(phone)) {
    //     return;
    // }

    // Create FormData object
    const formData = new FormData();
    formData.append('name', name.trim());
    formData.append('email', email.trim());
    if(selectedGender && selectedGender != "null" && selectedGender != "undefined"){
      formData.append('gender', selectedGender);
    }
    if(phone && phone != "null" && phone != "undefined"){
      formData.append('mobile', phone);
    } else {
      formData.append('mobile', null);
    }
    if(selectedAgeGroup && selectedAgeGroup != "null" && selectedAgeGroup != "undefined"){
      formData.append('age_group', selectedAgeGroup);
    }
    if(designation && designation != "null" && designation != "undefined"){
      formData.append('designation', designation.trim());
    } else {
      formData.append('designation', null);
    }
    if(department && department != "null" && department != "undefined") {
      formData.append('department', department.trim());
    } else {
      formData.append('department', null);
    }
    if(userLocation && userLocation != "null" && userLocation != "undefined") {
      formData.append('location', userLocation.trim());
    } else {
      formData.append('location', null);
    }
    if(selectedImage) {
      formData.append('image', selectedImage);
    } else {
      if(removeImage) {
        formData.append('image', null);
      }
    }
    // Make API call to update user profile
    try {
      const token = apiService.getAuthToken();
      const response = await apiService.put('update-org-user-info', formData, token);
  
      if (response && response.status === 'success') {
        // Handle success, e.g., show a success message or redirect
        CustomToast.success('Profile updated successfully!'); 
        onClose();
        navigate("/my_profile");
        
      } else {
        // Handle API error
        setError('Failed to update profile');
      }
    } catch (error) {
      // Handle other errors (network, etc.)
      if(error && error.message && error.message.includes('Mobile already exist')) {
        CustomToast.info('Mobile already exist.')
        return;
      }
      setError('An error occurred while updating profile');
      console.error(error);
    }
  };

  const handleRemoveImage = async ()=> {
    setSelectedImage(null);
    setImagePreview(null);
    setImageRemove(true)
  }

  const getInitials = (name) => {
    return name?.split(' ').map(word => word.charAt(0)).join('').toUpperCase() || '';
 };

  const handleClose = () => {
    onClose();
  };

  return (
    <div className='profile-form-wrapper m-auto'>
      <h3 className='font-weight-600 font-20'>Edit Profile</h3>
      <div className="card p-lg-3 p-0">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
          <div className='row'>
            <div className="form-group w-100 text-center mb-4">
                <label className='custom-image-upload'>
                    {!imagePreview && userData && userData?.name ? (      
                        <span className='username-initials fw-bolder font-28 text-white'>{getInitials(userData?.name)}</span>    
                    ) : null}

                    {imagePreview && (
                      <>
                        <img src={imagePreview} className='img-wrap' alt="Thumb" />
                        <div className="form-group w-100 text-center mb-4">
                              <span onClick={handleRemoveImage}>
                                <img src="/images/delete.png" className='remove-pic'></img>
                              </span>
                        </div>
                      </>

                    )}
                </label>

                {!imagePreview && userData && userData?.name ? (     
                  <label className='upload-profile-pic'>
                    <img src="/images/add.png" className='add-pic'></img>
                    <input
                        accept="image/*"
                        name="selectedImage"
                        type="file"
                        onChange={handleImageChange}
                      />
                  </label>
                ) : null}
            </div>

            </div>
            <div className='row'>
                <div className="col-lg-6 form-group mb-3">
                    <label className='mb-2'>Name*</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder='Name'
                        value={name}
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        required />
                </div>
                <div className="col-lg-6 form-group mb-3">
                    <label className='mb-2'>Email Id*</label>
                    <input
                        type="email"
                        className="form-control"
                        placeholder='Org. Email Id'
                        value={email}
                        name="email"
                        onChange={handleEmailChange}
                        required />
                    {emailError && <p className="error">{emailError}</p>}
                </div>
            </div>
            <div className='row'>

                <div className="col-lg-6 form-group mb-3">
                    <label className='mb-2'>Mobile</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder='xxxxx xxxxx'
                        value={phone}
                        name="phone"
                        onChange={handlePhoneChange}
                         />
                    {phoneError && <p className="error">{phoneError}</p>}
                </div>

                <div className="col-lg-6 form-group age mb-3">
                    <label htmlFor="ageSelect" className='mb-2'>Age Group</label>
                    <CustomSelect
                        name="selectedAgeGroup"
                        placeholder="Choose your Age Group"
                        options={ageGroupOptions}
                        value={selectedAgeGroup !== "null" ? selectedAgeGroup : ''}
                        onChange={handleAgeGroupChange}
                    />
                </div>
            </div>
            <div className='row'>
                <div className="col-lg-6 form-group gender mb-3">
                    <label htmlFor="genderSelect" className='mb-2'>Gender</label>
                    <CustomSelect
                        name="selectedGender"
                        placeholder='Select Gender'
                        options={genderOptions}
                        value={selectedGender !== "null" ? selectedGender : ''}
                        onChange={handleGenderChange}
                    />
                </div>

                <div className="col-lg-6 form-group mb-3">
                    <label className='mb-2'>Department</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder='e.g. IT'
                        value={department}
                        name="department"
                        onChange={handleDepartmentChange}
                    />
                </div>
            </div>
            <div className='row'>
                <div className="col-lg-6 form-group mb-3">
                    <label className='mb-2'>Designation</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder='e.g. Manager'
                        value={designation}
                        name="designation"
                        onChange={handleDesignationChange}
                    />
                </div>
                <div className="col-lg-6 form-group mb-3 location">
                    <label className='mb-2'>Location</label>
                    <input
                        type="text"
                        className="form-control text-capitalize"
                        placeholder='e.g. Pune'
                        value={userLocation}
                        name="userLocation"
                        onChange={handleLocationChange} 
                    />
                </div>
            </div>
            <div className='d-flex justify-content-between mt-4'>
                <button type='button' onClick={handleClose} className='btn btn-secondary w-100 me-2'>Cancel</button>
                <button type='submit' className='btn btn-primary w-100 ms-3'>Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProfileForm;