import React, { useState, useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import apiService from '../../../../services/apiService';
import {Link, useNavigate, useLocation, useAsyncValue} from 'react-router-dom';
import Modal from 'react-modal';
import InviteUserModal from '../../modal/inviteUserModal';
import CustomToast from '../../../Toast';
import Loader from '../../../layouts/loader';

const Admin = () => {

    const [show, setShow] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [courseLicenseList, setCourseLicenseList] = useState([]);
    const [inviteModalIsOpen, setInviteModalIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [userData, setUserData] = useState()
    const [selectedValue, setSelectedValue] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [orgID, setOrgID] = useState('')
    const [totalCount, setTotalCount] = useState(1)
    const [selectedUser, setSelectedUser] = useState(null);
    const percentage = 0;
    const navigate = useNavigate();


    useEffect(() => {
        // window.scrollTo(0, 0);
        let userDetail = sessionStorage.getItem("userDetail")
        if(userDetail) {
            userDetail = JSON.parse(userDetail)
            setUserData(userDetail)
        }
        let orgId = localStorage.getItem("ORGID")
        setOrgID(orgId)
        fetchCourseLicenses(orgId);
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [currentPage])


    const fetchCourseLicenses = (orgId) => {
        setLoading(true);
        setLoader(true);
        const token = apiService.getAuthToken()
        const org_id = localStorage.getItem('ORGID')
        apiService.get(`get_org_user_admin_list/${org_id || ''}?pageSize=50&page=${currentPage}`, token).then(
            (response) => {
            setCourseLicenseList((prevData) => [...new Set([...prevData, ...response?.data || []])]);
            setLoading(false);
            setLoader(false);
            },
            (error) => {
            setLoading(false);
            console.error("Error fetching data", error);
            setLoader(false);
            // CustomToast.error("Error while fetching course license list")
            }
        );
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        validateEmail(newEmail);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    }; 

    const openInviteModal = (element) => {
        setInviteModalIsOpen(true);
        setSelectedUser(element);
    };

    const closeInviteModal = () => {
        setInviteModalIsOpen(false);
        setSelectedUser(null);
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailPattern.test(email);
        if (!isValid) {
            setEmailError('Please enter a valid email address.');
        } else {
            setEmailError('');
        }
        return isValid
    };

    const handlePhoneChange = (e) => {
        const newPhoneNumber = e.target.value;
        setPhone(newPhoneNumber);
        validatePhone(newPhoneNumber);
    };

    const validatePhone = (phone) => {
        const phonePattern = /^[0-9]{10}$/;
        const isValid = phonePattern.test(phone);
        if (!isValid) {
            setPhoneError('Please enter a valid 10-digit phone number.');
        } else {
            setPhoneError('');
        }
        return isValid
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(email && !validateEmail(email)) {
            return
        }
        // if (phone && !validatePhone(phone)) {
        //       return;
        // }
        setLoader(true);
        let orgData = localStorage.getItem('orgDetails')
        if(orgData) {
            orgData = JSON.parse(orgData)
        }
        const payload = {
            orgid: orgData?.org_id,
            org_name: orgData?.org_name,
            users: [
                {
                    "fullname": name,
                    "email": email,
                    "mobile": phone
                }
            ]
        }
        const token = apiService.getAuthToken()
       // localStorage.setItem("orgInviteData", JSON.stringify(payload))
        apiService.post("send-org-link", payload, token).then((response)=> {
            if (response && response.status === "success") {
                setCourseLicenseList([])
                fetchCourseLicenses()
                // setCourseData(result.data);
               CustomToast.success('Invitation sent successfully');
                setLoader(false);
                //openModal()
                setName('')
                setEmail('')
                setPhone('')
              }
        }).catch((error)=> {
            setName('')
            setEmail('')
            setPhone('')
            console.error(error);
            setLoader(false);
            if(error && error.message && error.message.includes('User already exist')) {
                CustomToast.info('User already exist.')
              }
              else if(error && error.message && error.message.includes('Error occurred while sending invitations: Error: Error while sending invite: Error: Mobile already exist')) {
                CustomToast.info('Mobile already exist.')
              }
              else {
                CustomToast.error('Something went wrong.')
              }
            setLoader(false);
        })
    };

    const resendOrgInvite = async (element) => {
        const inviteInfo = {
            "orgid": element?.org_id,
            "org_name": element?.org_name,
            "fullname": element?.name,
            "email": element?.email,
            "mobile": element?.phone,
            "emailType": "org",
            "org_image": null,
            "invite_link": element?.invite_link,
        }

        try {
            const token = apiService.getAuthToken()
            const result = await apiService.post("org-reinvite", inviteInfo, token);
            if (result && result.status === "success") {
                CustomToast.success('Invitation sent successfully');
                //console.log("orgReinviteData", result);
            }
        } catch (error) {
            console.error(error);
            CustomToast.error('Something went wrong.')
        }
    };

    const fetchData = async () => {
      let courseData =localStorage.getItem("selectedCourse")
      courseData = JSON.parse(courseData)
      let orgData =localStorage.getItem("orgData")
      orgData = JSON.parse(orgData)
      let data = {
        "orgid": orgData.org_id,
        "org_name": orgData.org_name,
        "org_entity": selectedValue || orgData.org_name,
        "users": [
          {
            "fullname": name,
            "email": email,
            "mobile": phone
          }
        ]
      }
      try {
        const token = apiService.getAuthToken()
        const result = await apiService.post('send-and-assign-course', data, token);
        if (result && result.status === "success") {
          console.log(result, "kcdfnvidsfnvids");
          // setCourseData(result.data);
          openModal()
        }
      } catch (error) {
        //   setError('Error fetching user data');
        console.error(error);
        alert(error)
      }
    };

    const handleScroll = () => {
        const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        const clientHeight = document.documentElement.clientHeight || window.innerHeight;
        const scrollTrigger = 0.9;
    
        if (scrollTop + clientHeight >= scrollHeight * scrollTrigger && !loading && totalCount > currentPage) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    }

    function onSearch(e) {
        const value = e.target.value
        if(value) {
            const filteredOptions = courseLicenseList.filter(option =>
                option?.name?.toLowerCase().includes(value.toLowerCase()) || 
                option?.email?.toLowerCase().includes(value.toLowerCase()) || 
                option?.mobile?.toLowerCase().includes(value.toLowerCase())
            );
            setCourseLicenseList([])
            setCourseLicenseList(filteredOptions)
        } else {
            setCourseLicenseList([])
            fetchCourseLicenses(localStorage.getItem('ORGID'))
            setCurrentPage(1)
        }
    }

    const handleSort = () => {
        setCourseLicenseList((prevData) => {
            return [...prevData].reverse();
        });
      };

    const sendOrgInvite = ()=> {
        setShow(false)
    }

    const handleDeleteUser = (userId, uid) => {
        const token = apiService.getAuthToken()
        const obj = {uid: uid}
        apiService.delete(`remove-org-role/${userId}`, obj, token).then((res)=> {
            if(res && res?.status == "success") {
                CustomToast.success('Org Admin has been deleted')
                setCourseLicenseList([])
                fetchCourseLicenses(userData)
                setCurrentPage(1)
            }
        }).catch((err)=> {
            console.log(err)
            CustomToast.error('Something went wrong')
        })
      };

    return (
        <>
            {!show && <h4 className="d-flex justify-content-between fw-bold font-26 mb-4"><span>Assigned Admins</span>
                <a className="text-right text-decoration-none" onClick={() => setShow(true)}>
                    <img src="/images/account_plus.svg" alt="add admin" className="add_btn" /> 
                    <span className="button-default font-20">Add Admin</span>
                </a>
            </h4>}

            
            {show && 
                <div>
                    <h5 className="font-weight-600 font-26 mb-4">Add Admin</h5>
                    <div className="card">
                        <div className="card-body">
                            <div className="">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-4 form-group mb-3">
                                            <label className='mb-2 fw-bold'>Name*</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder='Name'
                                                value={name}
                                                name="name"
                                                onChange={(e) => setName(e.target.value)}
                                                required />
                                        </div>
                                        <div className="col-md-4 form-group mb-3">
                                            <label className='mb-2 fw-bold'>Email Id*</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder='Org. Email ID'
                                                value={email}
                                                name="email"
                                                onChange={handleEmailChange}
                                                required />
                                                {emailError && <p className="error">{emailError}</p>}
                                        </div>
                                        <div className="col-md-4 form-group mb-3">
                                            <label className='mb-2 fw-bold'>Mobile</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder='xxxxx xxxxx'
                                                value={phone}
                                                name="phone"
                                                onChange={handlePhoneChange}
                                                 />
                                                {phoneError && <p className="error">{phoneError}</p>}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-3 offset-md-9">
                                            <div className='d-flex justify-content-between mt-3'>
                                                <button type='submit' className='btn btn-secondary w-100 me-2' onClick={() => setShow(false)}>Cancel</button>
                                                <button type='submit' className='btn btn-primary w-100 ms-3'>Save</button>
                                            </div>

                                        </div>
                                    </div>

                                </form>

                                {loader ? <Loader /> : (
                                 <Modal
                                 isOpen={isModalOpen}
                                 onRequestClose={closeModal}
                                 contentLabel="Modal"
                                 className='Modal course-model bg-white p-4'
                               > {/*<button type="button" className="close text-white m-2" onClick={closeModal}>×</button>*/}
                                 <div className='py-4 px-5 w-100 text-center'>
                                   <p className='fw-bold font-20 mb-2 m-auto'>Invitation sent successfully.</p>
                                   <button onClick={closeModal} className='btn btn-md btn-primary mt-4 py-2'>Ok</button>
                                 </div>
                               </Modal>
                                )}
                              
                            </div>
                        </div>
                    </div>
                    <div className='hori_line'></div>
                </div>
            }

            <div className="form-group has-search w-25">
                <span className="form-control-feedback"><img src="/images/search.svg" alt="search" width={35} /></span>
                <input type="text" className="form-control" placeholder="Search" onChange={onSearch}/>
            </div>

            <table className="table table-responsive">
                <thead className="thead-dark">
                    <tr>
                        <th className="text-center">S. No.</th>
                        <th> <Link className='text-decoration-none text-black'><span onClick={handleSort} className='d-flex justify-content-between align-items-center'>Admin Name <img src='/images/sort.svg' alt='sort' className='me-5' width={20} height={20} /></span></Link></th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>

              {loader ? <Loader />
                :   <tbody>
                 {courseLicenseList && courseLicenseList?.map((element, index)=>{
                            return <tr>
                            <td align='center'>{index + 1}</td>
                            <td>{element?.name}</td>
                            <td>{element?.email}</td>
                            <td>{element?.mobile != "undefined" && element?.mobile != "null" && element?.mobile != "Null" ? element?.mobile : ''}</td>
                            {element?.user_id ? <td>ACCEPTED</td> :  <td>INVITED</td>}
                            {element?.user_id ? <td align='center'><Link onClick={() => handleDeleteUser(element?.org_user_id, element?.user_id)}><img src="/images/delete.svg" alt="delete" width={20} height={20} /></Link></td> : <td align='center'>
                                <Link onClick={() => openInviteModal(element)} >
                               <img src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB4PSIwIiB5PSIwIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgY2xhc3M9IiI+PGc+PHBhdGggZD0iTTM2MS4yMTMgMjczLjUxMmMtMjcuNTQ2IDE3LjQ4Mi00Ny4wMDcgNDYuNTYyLTUxLjUxMiA4MC4zN0gxNS4wNTlDNi43NDIgMzUzLjg4MiAwIDM0Ny4xNCAwIDMzOC44MjR2LS42MDNsMTQ0LjM5Ny0xMjMuNzY5IDY1LjI0MSA0Ni4zYTE1LjA2IDE1LjA2IDAgMCAwIDE3LjQzMSAwbDY1LjI0MS00Ni4zIDY4LjkwMyA1OS4wNnptNzUuNDkzLTE2LjUxN2ExMTQuMDQgMTE0LjA0IDAgMCAwLTE1LjA1OS0uOTk1IDExMy4wODYgMTEzLjA4NiAwIDAgMC0yOS45MTUgNC4wMDRsLTc0LjEwNy02My41MiAxMTkuMDgtODQuNTA4djE0NS4wMTloLjAwMXpNMCAyOTguNTUzVjExMS45NzZsMTE5LjA4IDg0LjUwOEwwIDI5OC41NTN6TTQzNi43MDYgNzUuMDQ1bC0yMTguMzUzIDE1NC45NkwwIDc1LjA0NXYtNy4yOGMwLTguMzE3IDYuNzQyLTE1LjA1OSAxNS4wNTktMTUuMDU5aDQwNi41ODhjOC4zMTcgMCAxNS4wNTkgNi43NDIgMTUuMDU5IDE1LjA1OXY3LjI4em0tNzUuMjk0IDMxNy43NzYgMTQuMTUtMTQuMTVjMi45NDYtMi45NDYgNy43MjUtMi45MTMgMTAuNjkzLjA1NWwxMC41NDggMTAuNTQ5YTcuNTQ3IDcuNTQ3IDAgMCAxIC4wMTQgMTAuNzMzTDM1NyA0MzkuODI1Yy01Ljg4MSA1Ljg4MS0xNS40MTYgNS44ODEtMjEuMjk2IDBsLTM5LjgxNy0zOS44MTdjLTIuOTMxLTIuOTMxLTIuOTU0LTcuNzY2LjAxNC0xMC43MzNsMTAuNTQ4LTEwLjU0OWMzLjAxNC0zLjAxMyA3Ljc1Ni0yLjk5MiAxMC42OTMtLjA1NWwxNC4xNSAxNC4xNXYtMjMuODhjMC00OS45MDEgNDAuNDUyLTkwLjM1MyA5MC4zNTMtOTAuMzUzUzUxMiAzMTkuMDQgNTEyIDM2OC45NDFzLTQwLjQ1MiA5MC4zNTMtOTAuMzUzIDkwLjM1M2gtNy40ODNjLTQuMTM5IDAtNy41NzYtMy40MDMtNy41NzYtNy42di0xNC45MTdjMC00LjI2MiAzLjM1LTcuNiA3LjQ4Mi03LjZoNy41NzdjMzMuMjY3IDAgNjAuMjM1LTI2Ljk2OCA2MC4yMzUtNjAuMjM1cy0yNi45NjgtNjAuMjM1LTYwLjIzNS02MC4yMzUtNjAuMjM1IDI2Ljk2OC02MC4yMzUgNjAuMjM1djIzLjg3OXoiIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDsiIGZpbGw9IiMxNDZiOTAiIG9wYWNpdHk9IjEiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSIiPjwvcGF0aD48L2c+PC9zdmc+' width={20} height={20} alt='reinvite-icon' />
                            </Link>                                 
                            </td>}
                        </tr>
                      })}
                </tbody>
              }
            </table>

            {selectedUser &&
                <Modal
                    isOpen={inviteModalIsOpen}
                    onRequestClose={closeInviteModal}
                    contentLabel="Invite Org Modal"
                >
                    <InviteUserModal onRequestClose={closeInviteModal} onConfirm={() => resendOrgInvite(selectedUser)} />
                </Modal>}
        </>
    )
}

export default Admin;