import React, { useState, useEffect} from 'react';
import CustomSelect from '../customSelect/customSelect';
import OrganisationServices from "../../../services/organisation"
import { useNavigate } from 'react-router-dom';
import Loader from '../../layouts/loader';
import apiService from '../../../services/apiService';
import Select from 'react-select';
import CustomToast from '../../Toast';

const CreateOrgnisation = () => {

    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [org_name, setOrgName] = useState('');
    const [org_size, setOrgSize] = useState('');
    const [org_sector, setOrgSector] = useState('');
    const [org_url, setOrgUrl] = useState('');
    const [org_address, setOrgAddress] = useState('');
    const [org_city, setOrgCity] = useState('');
    const [org_state, setOrgState] = useState('');
    const [org_pincode, setOrgPincode] = useState('');
    const [contact_name, setContactName] = useState('');
    const [contact_email, setContactEmail] = useState('');
    const [contact_mobile, setContactMobile] = useState('');
    const [contact_designation, setContactDesignation] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [selectedOrgSize, setSelectedOrgSize] = useState('');
    const [selectedSector, setSelectedSector] = useState('');
    const [photo, setPhoto] = useState('');
    const [selectedFile, setSelectedFile] = useState();
    const [selectedCertificateFile, setSelectedCertificateFile] = useState();
    const [file, setFile] = useState();
    const [certificateFile, setCertificateFile] = useState();
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate();
    const [entityInput, setEntityInput] = useState('');
    const [entities, setEntities] = useState([]);
    const [citiesList, setCitiesList] = useState([]);
    const [selectedShowCity, setSelectedShowCity] = useState('');
    const [isFetchingCities, setIsFetchingCities] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');


    const orgSizeOptions = [
        { value: '0 - 10', label: '0 - 10' },
        { value: '11 - 100', label: '11 - 100' },
        { value: '101 - 500', label: '101 - 500' },
        { value: '501 - 1000', label: '501 - 1000' },
        { value: '1000+', label: '1000+' },
    ];

    const sectorOptions = [
        { value: 'Agriculture', label: 'Agriculture' },
        { value: 'Automotive', label: 'Automotive' },
        { value: 'Banking', label: 'Banking' },
        { value: 'Biotechnology', label: 'Biotechnology' },
        { value: 'Chemicals', label: 'Chemicals' },
        { value: 'Construction', label: 'Construction' },

        { value: 'Consulting', label: 'Consulting' },
        { value: 'Consumer Goods', label: 'Consumer Goods' },
        { value: 'Education', label: 'Education' },
        { value: 'Energy', label: 'Energy' },
        { value: 'Engineering', label: 'Engineering' },
        { value: 'Entertainment', label: 'Entertainment' },

        { value: 'Environmental', label: 'Environmental' },
        { value: 'Fashion', label: 'Fashion' },
        { value: 'Finance', label: 'Finance' },
        { value: 'Food & Beverage', label: 'Food & Beverage' },
        { value: 'Government', label: 'Government' },
        { value: 'Healthcare', label: 'Healthcare' },

        { value: 'Hospitality', label: 'Hospitality' },
        { value: 'Information Technology (IT)', label: 'Information Technology (IT)' },
        { value: 'Insurance', label: 'Insurance' },
        { value: 'Legal', label: 'Legal' },
        { value: 'Manufacturing', label: 'Manufacturing' },
        { value: 'Marketing & Advertising', label: 'Marketing & Advertising' },

        { value: 'Media', label: 'Media' },
        { value: 'Nonprofit', label: 'Nonprofit' },
        { value: 'Pharmaceuticals', label: 'Pharmaceuticals' },
        { value: 'Real Estate', label: 'Real Estate' },
        { value: 'Retail', label: 'Retail' },
        { value: 'Telecommunications', label: 'Telecommunications' },

        { value: 'Transportation', label: 'Transportation' },
        { value: 'Travel & Tourism', label: 'Travel & Tourism' },
        { value: 'Utilities', label: 'Utilities' },
        { value: 'Other', label: 'Other' },
    ];

    useEffect(() => {
        handleCity();
        return () => {
            if (file && file.preview) {
                URL.revokeObjectURL(file.preview);
            }
        };
    }, [file]);

    useEffect(() => {
        handleCity(searchQuery);
      }, [searchQuery]);
    
      const handleInputChange = (event, {action}) => {
        const searchTerm = event;
        // Fetch cities only when the search query length is greater than or equal to 3 characters
        if (searchTerm?.length >= 3) {
            setSearchQuery(searchTerm);
        }
    };

    const handleCity = (searchQuery) => {
        setIsFetchingCities(true);
        const token = apiService.getAuthToken();
        apiService.get(`get-cities?city=${searchQuery || ''}`, token)      
        .then((response) => {
            const transformedCities = response.data.map(city => ({
                label: city.name,
                value: city.name,
                state: city.state_name
              }));
              setCitiesList(transformedCities);
        })
        .catch((e) => {
            console.log(e);
        })
        .finally(()=> {
            setIsFetchingCities(false);
        })
    }

    const handleCityChange = (selectedOption) => {
        setSelectedShowCity(selectedOption)
        setSelectedCity(selectedOption.value);
        setSelectedState(selectedOption.state);
    };

    const handleOrgSizeChange = (value) => {
        setSelectedOrgSize(value);
    }

    const handleSectorChange = (value) => {
        setSelectedSector(value);
    }

    const handleAddEntity = () => {
        if (entityInput.trim() !== '') {
            setEntities([...entities, entityInput.trim()]);
            setEntityInput('');
        }
    };

    const handleRemoveEntity = (index) => {
        const newEntities = [...entities];
        newEntities.splice(index, 1);
        setEntities(newEntities);
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setContactEmail(newEmail);
        validateEmail(newEmail);
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailPattern.test(email);
        if (!isValid) {
            setEmailError('Please enter a valid email address.');
        } else {
            setEmailError('');
        }
    };

    const handlePhoneChange = (e) => {
        const newPhoneNumber = e.target.value;
        setContactMobile(newPhoneNumber);
        validatePhone(newPhoneNumber);
    };

    const validatePhone = (phone) => {
        const phonePattern = /^[0-9]{10}$/;
        const isValid = phonePattern.test(phone);
        if (!isValid) {
            setPhoneError('Please enter a valid 10-digit phone number.');
        } else {
            setPhoneError('');
        }
    };

    // const handleImageChange = (e) => {
    //     setSelectedFile(e.target.files[0]);
    //     const selectedImage = e.target.files[0];
    //     setFile(URL.createObjectURL(selectedImage));

    // };

    const handleImageChange = (e) => {

        setSelectedFile(e.target.files[0]);
        const selectedImage = e.target.files[0];
        const allowedTypes = ['image/png','image/jpg','image/jpeg','image/svg+xml'];

        if(selectedImage && allowedTypes.includes(selectedImage.type)){
            setFile({ preview: URL.createObjectURL(selectedImage) });
        }else{
            CustomToast.error('Please select a PNG, JPG, SVG or JPEG file.');
        }
        
    };

    const handleCertificateImageChange = (e) => {
    
         setSelectedCertificateFile(e.target.files[0]);
         const selectedCertificateImage = e.target.files[0];
         const allowedTypes = ['image/png','image/jpg','image/jpeg','image/svg+xml'];
 
         if(selectedCertificateImage && allowedTypes.includes(selectedCertificateImage.type)){
             setCertificateFile({ preview: URL.createObjectURL(selectedCertificateImage) });
         }else{
             CustomToast.error('Please select a PNG, JPG, SVG or JPEG file.');
         }
 
     };

    // const handleFileChange = (event) => {
    
    // };

    const handleSend = (event) => {
        event.preventDefault();
        setLoader(true)
        const formData = new FormData();
        formData.append("org_name", org_name);
        formData.append("org_size", selectedOrgSize);
        formData.append("org_sector", selectedSector);
        formData.append("org_url", org_url);
        formData.append("org_address", org_address);
        formData.append("org_city", selectedCity);
        formData.append("org_state", selectedState);
        formData.append("org_pincode", org_pincode);
        formData.append("contact_name", contact_name);
        formData.append("contact_email", contact_email);
        formData.append("contact_mobile", contact_mobile);
        formData.append("contact_designation", contact_designation);
        if(selectedFile){
        formData.append("image", selectedFile, selectedFile.name);
        }
        if(selectedCertificateFile){
            formData.append("org_logo", selectedCertificateFile, selectedCertificateFile.name);
        }
        // const commaSeparatedValues = entities.map(item => item).join(',');
        if(entities && entities.length > 0) {
            const commaSeparatedValues = entities?.join(",")
            formData.append("entities", commaSeparatedValues);
        }

        const token = apiService.getAuthToken()
        apiService.postFormdata('create-org', formData, token)
        .then((response) => {
            setOrgName('');
            setSelectedOrgSize('');
            setSelectedSector('');
            setOrgUrl('');
            setEntities([]);
            setOrgAddress('');
            setSelectedCity('');
            setSelectedState('');
            setOrgPincode('');
            setContactName('');
            setContactEmail('');
            setContactMobile('');
            setContactDesignation('');
            setSelectedFile(null);
            setFile(null);
            setSelectedCertificateFile(null); 
            setCertificateFile(null);
            setLoader(false)
            CustomToast.success('Organization was created successfully.')
            navigate(`/organisation`);
        })
        .catch((e) => {
            setLoader(false)
            CustomToast.error(e.message);
        });
    }

    return (
        <>
            <div className='nosidebar-page__main'>
                <div className="dashboard">
                    <div className='profile-form-wrapper w-75 m-auto'>
                        <h3 className='font-weight-600 font-20'>Create Organisation</h3>
                        <div className="card p-lg-3 p-0">
                            {loader? <Loader /> :
                              <div className="card-body">

                              {/* <form onSubmit={handleSend}> */}
                              <form onSubmit={handleSend}>
                              
                                  <div className='row my-4'>
                                      <div className="form-group w-100 text-start mb-5">
                                          <label className='custom-image-upload org'>
                                          <div className='m-auto' style={{maxHeight: '200px', maxWidth: '200px'}}>
                                            <img src={file && file.preview} className='w-100' style={{maxHeight: '200px', maxWidth: '200px', objectFit: 'contain'}} />
                                          </div>
                                          <a className='img_upload'>Change Image
                                          <input
                                                  accept="image/*"
                                                  name="file"
                                                  type="file"
                                                  onChange={handleImageChange}
                                                  style={{ display: 'none' }} 
                                              />
                                          </a>
                                          </label>
                                      </div>
                                  </div>
                                  <h5 className="mb-4"><b>Basic Information</b></h5>
                                  <div className='row mb-4'>
                                      <div className="col-lg-4 form-group mb-3">
                                          <label className='mb-2'>Org. Name*</label>
                                          <input
                                              type="text"
                                              className="form-control"
                                              placeholder='Org. Name'
                                              value={org_name}
                                              name="org_name"
                                              onChange={(event) => setOrgName(event.target.value)}
                                              required />
                                      </div>
                                      <div className="col-lg-4 form-group mb-3">
                                          <label className='mb-2'>Org. Size*</label>
                                              <CustomSelect
                                                  id="orgSizeSelect"
                                                  name="selectedOrgSize"
                                                  placeholder="Select"
                                                  value={selectedOrgSize}
                                                  options={orgSizeOptions}
                                                  onChange={handleOrgSizeChange}>
                                              </CustomSelect>
                                      </div>
                                      <div className='col-lg-4 form-group mb-3'>
                                          <label className='mb-2'>Sector / Industry*</label>
                                              <CustomSelect
                                                  id="sectorSelect"
                                                  name="selectedSector"
                                                  placeholder="Select"
                                                  value={selectedSector}
                                                  options={sectorOptions}
                                                  onChange={handleSectorChange}>
                                              </CustomSelect>
                                      </div>
                                      <div className='col-lg-4 form-group mb-3'>
                                          <label className='mb-2'>Org. website*</label>
                                          <input
                                              type="text"
                                              className="form-control"
                                              placeholder='Org. Website'
                                              value={org_url}
                                              name="org_url"
                                              onChange={(e) => setOrgUrl(e.target.value)}
                                              required />
                                      </div>

                                  </div>
                                <h5 className='fw-bold mb-4'>Entities</h5>
                                <div className='row mt-4 mb-4'>
                                    <div className="col-lg-12 form-group mb-3">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder='Add entity'
                                                value={entityInput}
                                                onChange={(event) => setEntityInput(event.target.value)}
                                            />
                                            <button
                                                type="button"
                                                className="btn btn-primary ms-3"
                                                onClick={handleAddEntity}
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 entities">
                                        {entities.map((entity, index) => (
                                            <span key={index} className="badge btn-primary text-white w-auto me-2 mb-2">
                                                {entity}
                                                <button
                                                    type="button"
                                                    className="btn-close ms-2"
                                                    onClick={() => handleRemoveEntity(index)}
                                                />
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                  <h5 className="mb-4"><b>Organisation Address</b></h5>
                                  <div className='row mb-4'>
                                      <div className="col-lg-4 form-group mb-3">
                                          <label htmlFor="citySelect" className='mb-2'>Org. Address*</label>
                                          <input
                                              type="text"
                                              className="form-control"
                                              placeholder='Org. Address'
                                              value={org_address}
                                              name="org_address"
                                              onChange={(e) => setOrgAddress(e.target.value)}
                                              required />
                                      </div>

                                      <div className="col-lg-4 form-group mb-3">
                                          <label htmlFor="citySelect" className='mb-2'>City*</label>
                                            <Select
                                                options={citiesList}
                                                value={selectedShowCity}
                                                onInputChange={handleInputChange} // Add onInputChange handler
                                                isLoading={isFetchingCities}
                                                onChange={handleCityChange}
                                            />
                                      </div>

                                      <div className="col-lg-4 form-group mb-3">
                                          <label htmlFor="stateSelect" className='mb-2'>State*</label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder='State'
                                                value={selectedState}
                                                onChange={(event) => setSelectedState(event.target.value)}
                                                required readOnly
                                              />
                                      </div>

                                      <div className="col-lg-4 form-group mb-3">
                                          <label className='mb-2'>Postal Code*</label>
                                          <input
                                              type="text"
                                              className="form-control"
                                              placeholder='xxx xxx'
                                              value={org_pincode}
                                              name="org_pincode"
                                              onChange={(e) => setOrgPincode(e.target.value)}
                                              required
                                          />
                                      </div>
                                  </div>

                                  <h5 className="mb-4"><b>Primary Contact</b></h5>
                                  <div className='row mb-4'>
                                      <div className="col-lg-4 form-group mb-3">
                                          <label className='mb-2'>Name*</label>
                                          <input
                                              type="text"
                                              className="form-control"
                                              placeholder='Name'
                                              value={contact_name}
                                              name="contact_name"
                                              onChange={(e) => setContactName(e.target.value)}
                                              required />
                                      </div>
                                      <div className="col-lg-4 form-group mb-3">
                                          <label className='mb-2'>Email*</label>
                                          <input
                                              type="email"
                                              className="form-control"
                                              placeholder='Email ID'
                                              value={contact_email}
                                              name="contact_email"
                                              onChange={handleEmailChange}
                                              required />
                                          {emailError && <p className="error">{emailError}</p>}
                                      </div>

                                      <div className="col-lg-4 form-group mb-3">
                                          <label className='mb-2'>Mobile*</label>
                                          <input
                                              type="text"
                                              className="form-control"
                                              placeholder='xxxxx xxxxx'
                                              value={contact_mobile}
                                              name="contact_mobile"
                                              onChange={handlePhoneChange}
                                              required />
                                          {phoneError && <p className="error">{phoneError}</p>}
                                      </div>

                                      <div className="col-lg-4 form-group mb-3">
                                          <label className='mb-2'>Designation*</label>
                                          <input
                                              type="text"
                                              className="form-control"
                                              placeholder='Designation'
                                              value={contact_designation}
                                              name="contact_designation"
                                              onChange={(e) => setContactDesignation(e.target.value)}
                                              required />
                                      </div>
                                  </div>

                                  <h5 className="mb-4"><b>Certificate logo Upload</b></h5>

                                  <div className='row my-4'>
                                      <div className="form-group w-100 text-start mb-5">
                                          <label className='custom-image-upload org'>
                                          <div className='m-auto' style={{maxHeight: '200px', maxWidth: '200px'}}>
                                            <img src={certificateFile && certificateFile.preview} className='w-100' style={{maxHeight: '200px', maxWidth: '200px', objectFit: 'contain'}} />
                                          </div>
                                          <a className='img_upload'>Change Image
                                          <input
                                                  accept="image/*"
                                                  name="certificateFile"
                                                  type="file"
                                                  onChange={handleCertificateImageChange}
                                                  style={{ display: 'none' }} 
                                              />
                                          </a>
                                          </label>
                                      </div>
                                  </div>
                                  <div className='d-flex justify-content-end mt-4'>
                                      <a href="/organisation_details" className='btn btn-md btn-secondary w-25 me-2'>Cancel</a>
                                      <button className="btn btn-md btn-primary w-25 ms-3" type="submit">Save</button>
                                  </div>
                              </form>
                          </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateOrgnisation;